import { t } from "@/locales/translate";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { NextSeo } from "next-seo";

import CustomLink from "@/components/CustomLink";

const NotFoundPage = () => {
  return (
    <>
      <NextSeo title={t("404.title")} noindex={true} nofollow={true} />
      <article className="my-auto mt-24 flex h-60 flex-col items-center justify-center space-y-4 text-center">
        <h1 className="text-3xl font-semibold">
          404<span className="sr-only"> {t("404.title")}</span>
        </h1>
        <div className="flex items-center">
          <p>{t("404.body")}</p>
          <FaceFrownIcon width={32} height={32} className="ml-2 h-8 w-8" />
        </div>
        <p>
          <CustomLink href="/">{t("404.cta")}</CustomLink>
        </p>
      </article>
    </>
  );
};

export default NotFoundPage;
